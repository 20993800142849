import { useEffect, useState } from "react";
import Loading from "../components/Loading";

import { useMsalAuthentication } from "@azure/msal-react";
import { InteractionType, InteractionRequiredAuthError } from '@azure/msal-browser';

const Callback = () => {
  const request = {
    scopes: ["User.Read"]
}
const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, request);

useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
        login(InteractionType.Redirect, request);
    }
}, [error]);

  return <Loading isActive />;
};

export default Callback;
