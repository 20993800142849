import type { FC } from "react";
import PropTypes from "prop-types";
import commonStyle from "./commonStyle";
import { Box, Card, Divider, Stack, Typography } from "@mui/material";

interface OverviewStorageProps {
  amount: number;
  cost: number;
}

export const OverviewStorage: FC<OverviewStorageProps> = (props) => {
  const { amount ,cost} = props;

  return (
    <Card className="card" sx={commonStyle}>
      <Stack
        alignItems="center"
        direction={{
          xs: "column",
          sm: "row",
        }}
        spacing={3}
        sx={{
          px: 4,
          py: 3,
        }}
      >
        <div className="">
          <img src="\assets\computer\hard-disk.png" width={75} />
        </div>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            minHeight: "90px",
            justifyContent: "space-between",
            alignItems: "flex-end",
            flexDirection: "column",
          }}
        >
          <div className="flex flex-col items-end ">
            <Typography color="#ececec" variant="h5">
              STORAGE
            </Typography>
            <p color="" className="text-[#e1e1e1] text-end">
               0.50 THB/GB  
            </p>
          </div>
          <Typography color="#e1e1e1" variant="h5">
            {amount}
          </Typography>
        </Box>
      </Stack>
      <Divider />
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "space-between",
          paddingX: "10%",
          height: "50px",
          alignItems: "center",
          backgroundColor: "#2f363d",
        }}
      >
        <Typography color="#e1e1e1" variant="body2">
          Cost
        </Typography>
        <Typography color="#ececec" variant="h6">
          {cost} THB.
        </Typography>
      </Box>
    </Card>
  );
};

