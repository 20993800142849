import LoadingOverlay from "react-loading-overlay-ts";
const Loading = ({ isActive }: { isActive?: boolean }) => {
  return (
    <LoadingOverlay active={true} spinner text="Loading..." className="">
      <div style={{ height: "100vh",position:"relative",top:0 }}></div>
    </LoadingOverlay>
  );
};

export default Loading;
