export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID as string,
        authority: process.env.REACT_APP_AUTH_AUTHORITY as string,
        redirectUri: process.env.REACT_APP_REDIRECT_URL as string,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
};

export const loginRequest = {
    scopes: ["user.read"],
};

export const graphConfig = {
    graphMeEndpoint: process.env.REACT_APP_AUTH_GRAPH_ME_ENDPOINT
};
