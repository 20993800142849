import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CreateTasks,
  DeleteTask,
  DeleteTasks,
  GetProjectById,
} from "src/api/project";
import { UserContextType } from "src/types/userContextType";
import { useUserContext } from "src/contexts/ApexUserContext";
import Loading from "src/components/Loading";
import { dateReadableFormat } from "src/utils/date-format";
import TaskModal from "src/components/ProjectResource/TaskModal";
import Papa from "papaparse";
import CsvReader from "src/components/CSV/CsvReader";
import { Download01 } from "@untitled-ui/icons-react";
import { json } from "stream/consumers";

export interface Namespace {
  id: string;
  created_at: string;
  updated_at: string;
  project_id: string;
  owner_id: string;
  cluster_id: string;
  namespace: string;
  status: string;
}
export interface Task {
  id: string;
  created_at: string;
  updated_at: string;
  task_name: string;
  user_id: string;
  project_id: string;
  time_start: number;
  time_end: number;
  status: string;
  res_alloc: string;
}
export interface TasksCreate {
  owner_id: string;
  project_id: string;
  task_type: string;
  task_name: string;
  password: string;
  pvc_label: string[];
}
interface ProjectDetails {
  namespaces: any;
  id: string;
  created_at: string;
  updated_at: string;
  owner_id: string;
  name: string;
  default_namespace: string;
  user_name: string;
  tasks: Task[];
}
export interface CsvData {
  name: string;
  password: string;
  pvc: string;
}

const AdminProject = () => {
  let [createTaskModal, setcreateTaskModal] = useState(false);
  let [csvPopup, setcsvPopup] = useState(false);
  let navigate = useNavigate();
  const [data, setdata] = useState<ProjectDetails>();
  let id = process.env.REACT_APP_AUTH_GROUP_ID;
  const [loading, setLoading] = useState(true);
  const [csvData, setCsvData] = useState<CsvData[]>([]);
  let loaddata = () => {
    try {
      GetProjectById(id!).then((res) => {
        if (res) {
          setdata(res?.data!.project);
          setLoading(false);
        } else navigate("/500", { replace: true });
      });
    } catch (error) {}
  };
  function downloadCSV() {
    let firstline = "name,url,\n";
    const csvContent =
      firstline +
      data!.tasks
        ?.filter((e) => e.status === "Pending" || e.status === "Running")
        .sort((a, b) =>
          a.task_name.toLowerCase().localeCompare(b.task_name.toLowerCase())
        )
        .map((row) => `${row.task_name},${JSON.parse(row.res_alloc)?.url},`)
        .join("\n");

    // Create a Blob from the CSV string
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Create a link element for downloading the file
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "codeserver");

    // Append link to the body and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up by revoking the object URL and removing the link element
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
  const [csvDataTranform, setCsvDataTranform] = useState<TasksCreate[]>();
  useEffect(() => {
    if (csvData) {
      setCsvDataTranform(
        csvData.map((item: CsvData) => {
          return {
            owner_id: id as string,
            project_id: id as string,
            task_type: "codeserver",
            task_name: item.name,
            password: item.password,
            pvc_label: item?.pvc?.split(","),
          };
        })
      );
    }
  }, [csvData]);
  useEffect(() => {
    loaddata();
    if (!data) {
      // setInterval(loaddata, 5000);
    }
    const intervalId = setInterval(loaddata, 10000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  let submitData = () => {
    setLoading(true);
    CreateTasks(csvDataTranform as TasksCreate[]).then((res) => {
      let inp = document.getElementById("csv-inp") as HTMLInputElement;
      if (inp) {
        inp.value = "";
      }
      loaddata();
      setcsvPopup(false);
    });
  };
  if (loading) {
    return <Loading isActive={loading} />;
  }
  <Loading isActive={false} />;
  return (
    <div className="layout ">
      <CsvReader
        submit={submitData}
        csvData={csvData}
        isOpen={csvPopup}
        setCsvData={(val: any) => {
          setCsvData(val);
        }}
        setIsOpen={(val: boolean) => {
          setcsvPopup(val);
        }}
      />
      <TaskModal
        userId={id as string}
        isOpen={createTaskModal}
        setcreateTaskModal={setcreateTaskModal}
        projectId={id as string}
        loaddata={loaddata}
        loading={loading}
        setLoading={(val: boolean) => setLoading(val)}
        existName={
          data?.tasks
            .filter(
              (e: any) => e.status === "Running" || e.status === "Pending"
            )
            .map((e: any) => e.task_name) as string[]
        }
      />
      <div className="flex flex-col gap-y-5 w-full pt-5">
        <ProjectTask
          data={data!.tasks}
          downloadCsv={downloadCSV}
          setcreateTaskModal={setcreateTaskModal}
          refresh={() => {
            loaddata();
          }}
          loading={loading}
          setLoading={(val: boolean) => setLoading(val)}
          setCsv={(val: any) => {
            setCsvData(val);
          }}
          setcsvPopup={(val: boolean) => {
            setcsvPopup(val);
          }}
        />
      </div>
    </div>
  );
};

export default AdminProject;
let ProjectTask = ({
  data,
  setcreateTaskModal,
  refresh,
  loading,
  setLoading,
  setCsv,
  setcsvPopup,
  downloadCsv,
}: {
  data: Task[];
  setcreateTaskModal: (v: boolean) => void;
  setcsvPopup: (v: boolean) => void;
  refresh: () => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
  setCsv: (val: boolean) => void;
  downloadCsv: () => void;
}) => {
  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      Papa.parse(file, {
        header: true, // Set to true if the CSV has a header row
        skipEmptyLines: true,
        complete: function (results: any) {
          setCsv(results?.data);
          setcsvPopup(true);
        },
      });
    }
  };

  return (
    <div>
      <div className="w-full ">
        <div className="flex justify-between pr-3 mb-1 items-center">
          <h1 className="text-2xl font-bold  ">Your Task</h1>

          <div className="flex gap-x-2">
            <input
              id="csv-inp"
              className="hidden"
              type="file"
              accept=".csv"
              onChange={handleFileUpload}
            />
            <button
              onClick={() => {
                document.getElementById("csv-inp")?.click();
              }}
              className="nav-btn w-20 "
            >
              Upload
            </button>
            <button
              onClick={() => {
                downloadCsv();
              }}
              className="nav-btn w-25 "
            >
              Download
            </button>
            <button
              onClick={() => {
                setcreateTaskModal(true);
              }}
              className="nav-btn w-20 "
            >
              New
            </button>
            <button
              disabled={
                !(
                  data.filter(
                    (e) => e.status === "Running" || e.status === "Pending"
                  ).length > 0
                )
              }
              onClick={() => {
                setLoading(true);
                DeleteTasks({ id: data?.map((task) => task.id) }).then(
                  (res) => {
                    refresh();
                  }
                );
              }}
              className="nav-btn w-20 "
            >
              Stop All
            </button>
          </div>
        </div>
        <table id="task_Id" className="w-full ">
          <thead>
            <tr>
              <th>Task name</th>
              <th>Status</th>
              <th>Create At</th>
              <th>Start At</th>
              <th>End At</th>
              <th>Resource</th>
              <th>Menu</th>
            </tr>
          </thead>
          <tbody>
            {data
              ?.filter((e) => e.status === "Pending" || e.status === "Running")
              .sort((a, b) =>
                a.task_name
                  .toLowerCase()
                  .localeCompare(b.task_name.toLowerCase())
              )
              .map((task: Task) => {
                let resource = JSON.parse(task.res_alloc);

                return (
                  <tr key={task.id}>
                    <td>{task.task_name}</td>
                    <td>{task.status}</td>
                    <td>{dateReadableFormat(task.created_at)}</td>
                    <td>
                      {task.time_start > 0
                        ? dateReadableFormat(
                            new Date(task!.time_start * 1000).toISOString()
                          )
                        : "-"}
                    </td>
                    <td>
                      {task.time_end > 0
                        ? dateReadableFormat(
                            new Date(task!.time_end * 1000).toISOString()
                          )
                        : "-"}
                    </td>
                    <td>
                      <button
                        disabled={
                          !(
                            task.status === "Running" ||
                            task.status === "Pending"
                          )
                        }
                      >
                        <a
                          className="cursor-pointer !p-[5px] nav-btn !text-[16px]"
                          href={`https://${resource?.url}`}
                          target="_blank"
                        >
                          code server
                        </a>
                      </button>
                    </td>
                    <td>
                      <button
                        disabled={!(task.status === "Running")}
                        className="cursor-pointer !p-[5px] nav-btn"
                        onClick={() => {
                          setLoading(true);
                          DeleteTask({ id: task.id }).then(() => {
                            refresh();
                          });
                        }}
                      >
                        Stop
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
