import React from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  let navigate = useNavigate();

  return (
    <div className="h-[50vh] text-white flex justify-center items-center flex-col">
      <div className="text-[48px]">Page Not Found</div>
      <div className="text-[32px]">404</div>
      <button
        className="nav-btn"
        onClick={() => {
          navigate("/");
        }}
      >
        Back to Home page
      </button>
    </div>
  );
};

export default PageNotFound;
