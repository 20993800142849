import React from "react";
import { useNavigate } from "react-router-dom";

const ServerErr = () => {
  let navigate = useNavigate()
  return (
    <div className="h-[50vh] text-white flex justify-center items-center flex-col">
      <div className="text-[48px]">Unexpected error</div>
      <div className="text-[32px]">500</div>
      <button className="nav-btn" onClick={()=>{
        navigate("/")
      }}>Try Again</button>
    </div>
  );
};

export default ServerErr;
