export const dateReadableFormat = (dateString: string) => {
    const date = new Date(dateString);

    // ดึงข้อมูลส่วนต่าง ๆ ออกมา
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    // สร้าง format ที่ต้องการ
    return `${day}/${month}/${year}-${hours}:${minutes}:${seconds}`;
}
