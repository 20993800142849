import React, { useEffect, useState } from "react";
import { getUserKubeConfig } from "src/api/user";

type KubeConfigResult = {
  kubeconfigYAML: string;
};

const UserSetting = () => {
  const [userKubeConfig, setUserKubeConfig] = useState<any>(null);
  return (
    <div className="w-full bg-black">
      <div id="setting-user-token-section">
        <div className="flex justify-between">
          <h1 className="text-2xl ">User Kube config</h1>
          <button
            className="nav-btn"
            onClick={() => {
              if (!userKubeConfig) {
                getUserKubeConfig("test").then((res) => {
                  setUserKubeConfig(res);
                });
              } else {
                
                let config = document.getElementById("user-kube-config")?.innerHTML
                navigator.clipboard.writeText(config!)
              }
            }}
          >
            {!userKubeConfig ? "Get Config" : "Copy Config"}
          </button>
        </div>
        <div className="">
          {userKubeConfig && (
            <textarea
              className="bg-transparent w-full h-[600px] p-5"
              name="config"
              id="user-kube-config"
              value={userKubeConfig!.kubeconfigYAML}
            ></textarea>
          )}
        </div>
      </div>
    </div>
  );
};
export default UserSetting;
