import { useMsal } from "@azure/msal-react";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "src/contexts/ApexUserContext";
import {
  CreateDefaultProject,
  CreateProject,
  GetUserProject,
} from "src/api/project";
import Loading from "src/components/Loading";
import { UserContextType } from "src/types/userContextType";
import { dateReadableFormat } from "src/utils/date-format";
import ProjectModal from "src/components/Project/ProjectModal";
interface projectResData {
  error: string;
  projects: project[];
}
interface project {
  created_at: string;
  default_namespace: string;
  id: string;
  name: string;
  owner_id: string;
  updated_at: string;
}
const UserProject = () => {
  let { instance } = useMsal();
  let { user } = useUserContext() as UserContextType;
  const [projectData, setprojectData] = useState<projectResData>();
  const [loading, setLoading] = useState(true);
  const [openProjectModal, setOpenProjectModal] = useState(false);
  let activeAccount = instance.getActiveAccount();
  let loaddata = () => {
    try {
      GetUserProject(user?.ad_id as string).then((res) => {
        if (!!res) {
          setprojectData(res?.data);
          setLoading(false);
        } else navigate("/500", { replace: true });
      });
    } catch (error) {}
  };
  let navigate = useNavigate();

  useEffect(() => {
    if (activeAccount) {
      loaddata();
    }
    return () => {};
  }, []);
  if (loading) {
    return <Loading isActive={loading} />;
  }
  return (
    <div className="layout">
      <ProjectModal
        setOpenModal={(val: boolean) => {
          setOpenProjectModal(val);
        }}
        isOpen={openProjectModal}
        data={""}
        reload={() => {
          loaddata();
        }}
      />
      {projectData!.projects?.length! > 0 && (
        <div className="w-full flex flex-row justify-between mt-10 mb-5">
          <h1 className="text-4xl font-bold">Your Projects</h1>
          <button
            onClick={() => {
              setOpenProjectModal(true);
            }}
            className="nav-btn"
          >
            New
          </button>
        </div>
      )}

      {!(projectData!.projects?.length! > 0) ? (
        <div className="w-full h-full">
          <div className=" flex flex-col justify-center items-center gap-y-6 pb-10  h-full">
            <h1 className="text-3xl">
              You currently have no projects available.
            </h1>
            <button
              onClick={() => {
                setLoading(true);
                CreateDefaultProject({
                  owner_id: user?.ad_id as string,
                  user_name: user?.ipa_name as string,
                  project_name: "default",
                }).then((res) => {
                  GetUserProject(activeAccount?.localAccountId!).then((res) => {
                    if (!!res) {
                      setprojectData(res?.data);
                      setLoading(false);
                    }
                  });
                });
              }}
              className="nav-btn "
            >
              Get Start With Default Project
            </button>
          </div>
        </div>
      ) : (
        <div className="w-full">
          <table id="task_Id" className="w-full ">
            <thead>
              <tr>
                <th>Project Name</th>
                <th>Create at</th>
                <th>Last update</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!projectData?.error &&
                projectData?.projects?.map((item) => (
                  <tr
                    key={item.id}
                    onClick={() => {
                      navigate(`${item.name}/${item.id}`, { replace: false });
                    }}
                  >
                    <td className="text-xl">{item.name}</td>
                    {/* <td>{dateReadableFormat(item.created_at)}</td> */}
                    <td>{dateReadableFormat(item.created_at)}</td>
                    <td>{dateReadableFormat(item.updated_at)}</td>
                    <td>
                      <div className="flex gap-x-2 justify-center">
                        <button disabled className=" nav-btn">
                          Edit
                        </button>
                        <button disabled className="nav-btn">
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default UserProject;
