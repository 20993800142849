import {  requestUser } from "../../api/user";
import { useMsal } from "@azure/msal-react";
import "./Style.css";
import { Link } from "react-router-dom";

const SetAccount = () => {
  return (
    <div className="layout set-account">
      <div id="bind-acc-con">
      <div>
          <div>If you already have an Account</div>
          <Link  className="setAccountBtn" to={"/bind-account"}          >
            <span>Bind Account</span>
          </Link>
        </div>
        <div>
          <div>You don't have any Account on Apex?</div>
          <Link  to={"/request-account"} className="setAccountBtn"
          >
            Request Account
          </Link>
        </div>
        
      </div>
    </div>
  );
};

export default SetAccount;
