import { useMsal } from "@azure/msal-react";
import { checkUser, mapUser } from "../../api/user";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useUserContext } from "../../contexts/ApexUserContext";
import { UserContextType } from "../../types/userContextType";

import Loading from "../../components/Loading";
const BindAccount = () => {
  let { updateUser } = useUserContext() as UserContextType;
  let { accounts } = useMsal();

  let ad_id = accounts[0].localAccountId;
  let email = accounts[0].username;

  const [uname, setUname] = useState<string>("");
  const [loading, setLoading] = useState(false);
  let navigation = useNavigate();
  if (loading) {
    return <Loading isActive={true} />;
  }
  return (
    <div className="layout bind-account">
      <div className="bind-account-layout">
        <div className="left"></div>
        <div className="right">
          <div className="form-topic">Bind your account with Apex-account</div>
          <div id="bind-acc-con">
            <div>
              <div className="input-box">
                <label htmlFor="uname">IPA username</label>
                <input
                  id="uname-input"
                  required
                  name="uname"
                  type="text"
                  value={uname}
                  onChange={(e) => {
                    setUname(e.target.value);
                  }}
                />
                <p
                  className="text-[10px] 
                opacity-95 text-slate-300"
                >
                  enter IPA account that match with your email
                </p>
              </div>
              <button
                type="submit"
                form="req-form"
                className="setAccountBtn bindBtn"
                onClick={async () => {
                  if (uname !== "") {
                    setLoading(true);
                    await mapUser(ad_id, uname, email).then((res) => {
                      if (res?.success) {
                        checkUser(accounts[0].localAccountId).then((res) => {
                          if (res?.validUser) {
                            updateUser(res?.data);
                            setLoading(false);
                            navigation("/");
                          }
                          setLoading(false);
                        });
                      } else {
                        setLoading(false);
                        alert(`Bind account uncompleted:${res?.data.response}`);
                      }
                    });
                  }
                }}
              >
                Bind Account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BindAccount;
