import { axiosClient as axios } from "../API"
import { API, createEndpoint } from "../API"; 
let { endpoint } = API

// ---
export let checkUser = async (ad_id: string) => {
    let token = ''

    let config = {
        headers: {
            // "Authorization": `Bearer ${token}`
            // "Content-Type": "application/json"
        }

    }
    let body = {
        ad_id
    }
    try {
        let res = await axios.post(createEndpoint(endpoint.checkUser), body, config)

        if (res.status === 201) {
            return {
                validUser: true,
                data: res.data.user
            }
        } else if (res.status === 204) {
            return {
                validUser: false,
                data: null
            }
        } else return {
            err: "server error"
        }

    } catch (error) {
        console.log("Network error: " + error);
        return {
            err: "server error"
        }
    }
}

export let requestUser = async (
    ad_id: string,
    firstname: string,
    lastname: string,
    email: string
) => {
    let token = ''
    let config = {
        // headers: { Authorization: `Bearer ${token}` }
    }
    let body = {
        ad_id,
        firstname,
        lastname,
        email,
    }
    try {
        let res = await axios.post(createEndpoint(endpoint.requestUser), body, config)
        console.log(res.data);

        if (res.status === 201 && !res.data.error) {
            return {
                success: true,
                data: res.data
            }
        } else {
            return {
                success: false,
                data: res.data.error
            }
        }
    } catch (error) {
        console.log(error);

    }


}
export let mapUser = async (
    ad_id: string,
    apex_username: string,
    ad_email: string
) => {
    let token = ''
    let config = {
        // headers: { Authorization: `Bearer ${token}` }
    }
    let body = {
        ad_id,
        apex_username,
        ad_email
    }
    try {
        let res = await axios.post(createEndpoint(endpoint.mapUser), body)

        if (res.status === 201 && res?.data.ad_id) {
            return {
                success: true,
                data: res.data
            }
        } else {
            return {
                success: false,
                data: res.data
            }
        }

    } catch (error) {
        return {
            success: false,
            data: { response: "Network Try again" }
        }
    }


}
export let getUserKubeConfig = async (name: string) => {
    try {
        let res = await axios.get(createEndpoint(endpoint.getUserKubeConfig + "/" + name))
        return res.data

    } catch (error) {
        console.log("Network error: " + error);
        return {
            err: "server error"
        }
    }
}

export let getUserKubeConfigByNamespace = async (username: string,namespace: string) => {
    try {
        let res = await axios.get(createEndpoint(endpoint.getUserKubeConfig + "/" + username+ "/" + namespace))
        return res.data

    } catch (error) {
        console.log("Network error: " + error);
        return {
            err: "server error"
        }
    }
}