import type { FC } from "react";
import PropTypes from "prop-types";
import commonStyle from "./commonStyle";
import { Box, Card, Stack, Typography } from "@mui/material";

interface OverviewDoneTasksProps {
  amount: number;
}

export const OverviewDoneTasks: FC<OverviewDoneTasksProps> = (props) => {
  const { amount } = props;

  return (
    <Card  className="card" sx={commonStyle}>
      <Stack
        alignItems="center"
        direction={{
          xs: "column",
          sm: "row",
        }}
        spacing={3}
        sx={{
          px: 4,
          py: 3,
        }}
      >
        <div>
          <img src="\assets\checked.png" width={48} />
        </div>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography color="#ececec" variant="h5">
          Completed Tasks
          </Typography>
          <Typography color="#e1e1e1" variant="h5">
            {amount}
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};

