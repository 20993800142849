import { createContext, useContext, useState, ReactNode } from "react";
import { User, UserContextType } from "../types/userContextType";

const UserContext = createContext<UserContextType | null>(null);

export let useUserContext = () => useContext(UserContext);

let UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User>();
  let updateUser = (user: User | undefined) => {
    if (user?.access_token) {
      document.cookie = `token=${user.access_token}`;
    }
    setUser(user);
  };
  const userStore = {
    user,
    updateUser,
  };
  return (
    <UserContext.Provider value={userStore}>{children}</UserContext.Provider>
  );
};
export default UserProvider;
