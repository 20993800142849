import { axiosClient as axios } from "../API"
import { API, createEndpoint } from "../API"
import { DataUsage } from "../../interfaces/UsageData"

let { endpoint } = API
let getResourceUsageCurrentMouth = async (uid: number,ad_id:string): Promise<DataUsage> => {
    try {
        let res = await axios.get(createEndpoint(endpoint.userUsages), {
            params: {
                uid,
                ad_id
            }
        })
        let data = await res.data
        return data
    } catch (error) {
        console.log(error);
    }
    return {
        CPUCost: 0,
        CPUTime: 0,
        GPUCost: 0,
        GPUTime: 0,
        Storage: 0,
        StorageCost: 0,
        Success: false,
        TotalTask: 0,
        TaskList: [],
        KubeTaskList: []
    }
}
let getResourceUsageThatMouth = async (data: { uid: number, month: number, year: number }): Promise<DataUsage> => {
    let { uid, month, year } = data
    try {
        let res = await axios.get(createEndpoint(endpoint.userUsagesPermonth), {
            params: {
                uid, month, year
            }
        })
        let data = await res.data
        
        return data
    } catch (error) {
        console.log(error);
    }
    return {
        CPUCost: 0,
        CPUTime: 0,
        GPUCost: 0,
        GPUTime: 0,
        Storage: 0,
        StorageCost: 0,
        Success: false,
        TotalTask: 0,
        TaskList: [],
        KubeTaskList: []
    }
}
export { getResourceUsageCurrentMouth,getResourceUsageThatMouth } 