import { fastMemo } from "@mui/x-data-grid/internals";
import React, { useEffect, useState } from "react";
import { CreateTasks } from "src/api/project";
import { CsvData, TasksCreate } from "src/pages/Admin/Project";

const CsvReader = ({
  isOpen,
  setIsOpen,
  csvData,
  setCsvData,
  submit,
}: {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  csvData: CsvData[];
  setCsvData: (val: { name: string; password: string }[]) => void;
  submit: () => void;
}) => {
  if (isOpen) {
    return (
      <div className="cover absolute  h-full w-full bg-black  bg-opacity-80 mb-3 rounded flex justify-center items-center">
        <div
          className={`flex flex-col relative rounded px-12 py-8 min-w-[500px] w-[60%] h-[60%] bg-[#0e1219] gap-y-3 `}
        >
          <div
            onClick={() => {
              setIsOpen(false);
              let inp = document.getElementById("csv-inp") as HTMLInputElement;
              if (inp) {
                inp.value = "";
              }
            }}
            className="text-2xl text-white absolute right-[20px] top-[10px] cursor-pointer"
          >
            x
          </div>
          <div className=" h-full bg-opacity-75 overflow-y-scroll">
            <table className="w-[80%]">
              <thead>
                <tr>
                  {csvData.length > 0 &&
                    Object.keys(csvData[0]).map((key) => (
                      <th key={key}>{key}</th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {csvData.map((row: { name: string; password: string }) => (
                  <tr key={row.password}>
                    {Object.values(row).map((value: any, i) => (
                      <td key={i}>{value}</td>
                    ))}
                    {/* <td className="px-3" align="left">
                      {row?.name}
                    </td>
                    <td className="px-3" align="left">
                      {row?.password} 
                    </td>*/}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end gap-x-2">
            <button
              onClick={() => {
                submit();
              }}
              className="nav-btn flex-1"
            >
              Create
            </button>
          </div>
        </div>
      </div>
    );
  } else return <></>;
};

export default CsvReader;
