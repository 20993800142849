import { ReactElement, ReactNode, useEffect } from "react";
import { checkUser } from "../api/user";
import { useMsal } from "@azure/msal-react";
import { useUserContext } from "../contexts/ApexUserContext";
import { UserContextType } from "../types/userContextType";
import { Navigate, Outlet, OutletProps, useNavigate } from "react-router-dom";
import { JSX } from "react/jsx-runtime";
import Loading from "../components/Loading";
import { isAdmin } from "./validateGroup";
const AdminRoute = (
  props: JSX.IntrinsicAttributes & OutletProps
): React.ReactElement | null => {
  let { user } = useUserContext() as UserContextType;
  useEffect(() => {}, [user]);

  if (isAdmin(user?.groups)) {
    return <Outlet {...props} />;
  } else return <Navigate to={"/"} />;
};
export default AdminRoute;
