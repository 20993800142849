import { useMsal } from "@azure/msal-react";
import { checkUser, requestUser } from "../../api/user";
import { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../contexts/ApexUserContext";
import { UserContextType } from "../../types/userContextType";

const RequestAccount = () => {
  let { accounts } = useMsal();
  let ad_id = accounts[0].localAccountId;
  let email = accounts[0].username;
  let { updateUser } = useUserContext() as UserContextType;
  const [name, setName] = useState<string>("");
  const [lname, setLname] = useState<string>("");
  const [loading, setLoading] = useState(false);
  let navigation = useNavigate();

  console.log(accounts[0]);
  let defaultName = accounts[0]?.name?.split(" ");

  useEffect(() => {
    if (defaultName) {
      setName(defaultName[0]);
      setLname(defaultName[1]);
    }
  }, []);
  console.log(defaultName);
  if (loading) {
    return <Loading isActive={true} />;
  }

  return (
    <div className="layout bind-account">
      <div className="bind-account-layout">
        <div className="right">
          <div id="req-acc-con">
            <div>
              <div className="input-box">
                <label htmlFor="name">First name</label>
                <input
                  name="name"
                  className="account-input"
                  type="text"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="input-box">
                <label htmlFor="lname">Last name</label>
                <input
                  name="lname"
                  className="account-input"
                  type="text"
                  value={lname}
                  onChange={(e) => {
                    setLname(e.target.value);
                  }}
                />
              </div>
              <button
                type="submit"
                form="req-form"
                className="setAccountBtn reqBtn"
                onClick={() => {
                  setLoading(true);
                  requestUser(ad_id, name, lname, email).then((res) => {
                    if (res?.success) {
                      checkUser(accounts[0].localAccountId).then((res) => {
                        if (res?.validUser) {
                          updateUser(res?.data);
                          setLoading(false);
                          navigation("/");
                        }
                      });
                    } else {
                      setLoading(false);
                      alert("Request account uncompleted");
                    }
                  });
                }}
              >
                Request Account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestAccount;
