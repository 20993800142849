import React, { useState } from "react";
import "./style.css";
import UserSetting from "./components/UserSetting";
const Setting = () => {
  const settingMenu = [{ menu: "user", component: UserSetting }];
  // const [settingContent, setsettingContent] = useState(
  //   settingMenu[0].component
  // );
  return (
    <div className="layout !py-10 !flex-row">
      <div id="setting-menu-bar" className=" h-full  left-0  w-[25%]  top-0">
        <h2 className="mb-2 text-3xl ">Setting</h2>
        <div className="border-[1px] border-white">
          {settingMenu?.map((e) => (
            <div
              key={e.menu}
              className="setting-menu-btn"
              onClick={() => {
                // setsettingContent(e.component);
              }}
            >
              <p>{e.menu}</p>
            </div>
          ))}
        </div>
      </div>
      <div id="setting-content" className="p-5 h-full w-[75%]">
        {settingMenu[0].component()}
      </div>
    </div>
  );
};

export default Setting;
